<template>
  <div class="row">
    <div class="col-12">
      <Breadcrumb :items="breadcrumbs" />
      <div class="lk">
        <div class="row">
          <div class="col-lg-3">
            <div class="lk-nav">
              <UserBar />
              <SideBar />
            </div>
          </div>
          <div class="col-lg-9">
            <div class="catalog__content">
              <div class="catalog__head mb-0">
                <div class="catalog__title title title--secondary">
                  Список желаний
                </div>
                <div class="catalog__controls">
                  <button class="btn-select-all">
                    Выбрать все
                  </button>
                  <button class="btn-remove">
                    <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.65905 3.38489H1.34692C1.26782 3.37774 1.18943 3.40556 1.13015 3.46181C1.07187 3.5214 1.04488 3.60768 1.05791 3.69257L1.80934 10.6615C1.89747 11.4298 2.5129 12.0056 3.23996 11.9999H6.88158C7.62911 12.0112 8.25879 11.4079 8.32666 10.6154L8.94804 3.6618C8.95189 3.58722 8.92561 3.51443 8.87579 3.46181C8.81655 3.40556 8.73816 3.37774 8.65905 3.38489ZM7.74866 10.5846C7.70324 11.053 7.32363 11.4033 6.8816 11.3845H3.23999C2.8059 11.4033 2.43277 11.0599 2.38738 10.6L1.66485 4.00025H8.34113L7.74866 10.5846Z"/>
                      <path d="M9.71098 1.38472H6.73409V0.892419C6.75013 0.416927 6.40107 0.0176414 5.95442 0.000562779C5.93976 3.30712e-06 5.92507 -0.000143922 5.91041 0.000150536H4.08959C3.64272 -0.00865378 3.27379 0.369843 3.26552 0.845571C3.26524 0.861177 3.26538 0.876813 3.26591 0.892419V1.3847H0.289018C0.129393 1.3847 0 1.52244 0 1.69238C0 1.86231 0.129393 2.00006 0.289018 2.00006H9.71098C9.87061 2.00006 10 1.86231 10 1.69238C10 1.52244 9.87058 1.38472 9.71098 1.38472ZM6.15606 0.892419V1.3847H3.84392V0.892419C3.82762 0.757587 3.9171 0.634238 4.04376 0.616894C4.05894 0.614804 4.07429 0.614362 4.08956 0.61551H5.91038C6.03776 0.605911 6.14834 0.708029 6.15736 0.843657C6.15846 0.859911 6.15802 0.876253 6.15606 0.892419Z"/>
                    </svg>
                    Удалить
                  </button>
                </div>
              </div>
              <div class="row goods-grid goods-grid--lk">
                <div class="col-md-4 col-6 goods-grid__item">
                  <Item/>
                </div>
              </div>
              <button class="btn btn--show-more btn--top-indent">
                <svg class="icon-svg btn--show-more__icon"><use xlink:href="dist/img/sprite.svg#load-more"></use></svg>
                Посмотреть еще
              </button>
              <!--<b-pagination
                      v-model="currentPage"
                      align="center"
                      :total-rows="rows"
                      :per-page="perPage"
                      :ellipsis-class="[pageClass, pageClassEllipsis]"
                      :page-class="pageClass"
                      :next-class="[pageClass, pageClassArrow]"
                      :prev-class="[pageClass, pageClassArrow]"
                      first-class="d-none"
                      last-class="d-none"
                  >
                    <template #next-text>
                      <svg width="8" height="15" class="icon-svg" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.18758 1.25L7.41675 7.47917L1.18758 13.7083" stroke="currentColor" stroke-width="0.958333" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </template>
                    <template #prev-text>
                      <svg width="8" height="15" class="icon-svg" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.43742 1.25L1.20825 7.47917L7.43742 13.7083" stroke="currentColor" stroke-width="0.958333" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </template>
                  </b-pagination>
-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Callback />
</template>
<script>
import { useMeta } from 'vue-meta'
import { useI18n } from 'vue-i18n'
import Callback from '~/pages/account/partials/callback'
import UserBar from '~/pages/account/partials/userBar'
import SideBar from '~/pages/account/partials/sidebar'
import Item from './partials/item'
export default {
  components: {
    Callback,
    UserBar,
    SideBar,
    Item
  },
  setup () {
    const { t } = useI18n()
    useMeta({
      title: t('wishlist')
    })
    return {
      t,
      breadcrumbs: [
        { name: t('home'), href: '/' },
        { name: t('wishlist'), href: null, active: true }
      ]
    }
  }
}
</script>
